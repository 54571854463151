import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import Leadslist from '../Components/Leads/Leadslist'


function AllLeads() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3'>
                        <Menu />
                    </div>
                    <div className='col-sm-9'>
                        <Leadslist />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AllLeads