import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Select, Upload, ColorPicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useGetCategoryQuery,
  useGetTypesQuery,
} from "../../RTK/Sevice/ApiService";

import Loader from "../Loader";
import toast from "react-hot-toast";
import { useCreateJokeMutation } from "../../RTK/Sevice/ApiListing";

const { Option } = Select;

const AddList = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [colorCode, setColorCode] = useState();
  const [selectedJokeType, setSelectedJokeType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Get Type Api

  const { data: types } = useGetTypesQuery();

  const { data: category } = useGetCategoryQuery();
  const [createJokeMutation, { isLoading, error, data }] =
    useCreateJokeMutation();

  const handleJokeTypeChange = (value, option) => {
    setSelectedJokeType(option.children);
    setSelectedCategory();
  };

  const handleJokeCategoryChange = (value, option) => {
    setSelectedCategory(option.children);
  };
  const handleColorChange = (color) => {
    const metaColor = color.metaColor;
    const hexColor = `#${metaColor.toHex()}`;
    setColorCode(hexColor);
  };
  // End Get Api
  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      if (values.Title) {
        formData.append("Title", values.Title);
      }
      if (values.Description) {
        formData.append("Description", values.Description);
      }
      if (values.url) {
        formData.append("url", values.url);
      }

      if (colorCode) {
        formData.append("BackgroundColor", colorCode);
      }

      formData.append("JokeType", values.JokeType);
      if (values.JokeCategory) {
        formData.append("JokeCategory", values.JokeCategory);
      }

      if (values.Media) {
        formData.append("Media", values.Media[0]);
      }

      const response = await createJokeMutation(formData); // Use await since it's an async function

      if (response.data) {
        toast.success("Data Post successfully");

        // Set the token in the cookie or wherever it needs to be stored

        navigate("/listing");
      } else if (response.error) {
        toast.error(response.error.data.message || "An error occurred");
      }
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = ({ fileList }) => {
    return fileList.map((file) => file.originFileObj);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <Fragment>
      <div className="col-sm-12 addform">
        <h2>Add New Joke Or Qoute</h2>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="JokeType"
            rules={[{ required: true, message: "Type is required" }]}
          >
            <Select placeholder="Select Type" onChange={handleJokeTypeChange}>
              {types?.result?.items?.map((items) => (
                <Option value={items?._id}>{items.name}</Option>
              ))}
            </Select>
          </Form.Item>
          {selectedJokeType !== "joke" && (
            <Form.Item
              name="JokeCategory"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select
                placeholder="Select Category"
                onChange={handleJokeCategoryChange}
              >
                {category?.result?.items.map((items) => (
                  <Option value={items?._id}>{items.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedJokeType == "joke" && (
            <Form.Item
              name="Title"
              rules={[{ required: true, message: "Please Add Title!" }]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          )}
          {selectedCategory == "text" && (
            <Form.Item
              name="Title"
              rules={[{ required: true, message: "Please Add Title!" }]}
            >
              <Input placeholder="Title of Qoute" />
            </Form.Item>
          )}

          {selectedCategory == "song" && (
            <Form.Item
              name="url"
              rules={[
                { required: true, message: "Please Add Song Url!" },
                { type: "url", message: "Please enter a valid URL!" }, // URL validation rule
              ]}
            >
              <Input placeholder="Song Url" />
            </Form.Item>
          )}

          <div className="row">
            {selectedJokeType !== "joke" && selectedCategory == "image" && (
              <div className="col-sm-12">
                <Form.Item
                  name="Media"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: true, message: "Please Add Media File!" }]}
                  
                >
                  <Upload
                    // beforeUpload={beforeImageUpload}

                    name="logo"
                    beforeUpload={() => false}
                    action="/upload.do"
                    listType="picture"
                    accept="image/*, audio/*"
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to Select Media File
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            )}
          </div>

          {selectedJokeType == "joke" && (
            <Form.Item
              name="Description"
              rules={[{ required: true, message: "Please Add Description!" }]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          )}

          {selectedJokeType !== "joke" && selectedCategory == "text" && (
            <Form.Item
              name="Description"
              rules={[{ required: true, message: "Please Add Description!" }]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          )}

          {selectedCategory == "song" || selectedCategory == "image" ? (
            <></>
          ) : (
            <Form.Item
              name="BackgroundColor"
              label="Select Background Color"
              className="colors"
            >
              <ColorPicker onChange={handleColorChange} />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit">
              {isLoading ? <Loader /> : "  Add On List"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddList;
