import logo from './logo.svg';
import './App.css';
import './Style/Style.css';
import './Style/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Dashboard from "./Layout/Dashboard";

import AOS from "aos";
import "aos/dist/aos.css";
import LoginPage from "./Layout/LoginPage";
import AllLeads from "./Layout/AllLeads";
import AddListItem from "./Layout/AddListItem";
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoutes";
import EditListItem from "./Layout/EditListItem";
import Cookies from "js-cookie";
import ChangePassword from "./Layout/ChangePassword";
import StatsService from './Layout/Stats';

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out-back",
    });
  }, []);
  const token = Cookies.get("token")


  return (
    <Fragment>
      <Routes>
        <Route path="/" exact element={<LoginPage />} />

        {/* <Route path="/dashboard" exact element={<Dashboard />} /> */}

        <Route
          path="/dashboard"
          exact
          element={
            <ProtectedRoute token={token}>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/listing"
          exact
          element={
            <ProtectedRoute token={token}>
              <AllLeads />
            </ProtectedRoute>
          }
        />

        <Route
          path="/service-stats"
          exact
          element={
            <ProtectedRoute token={token}>
              <StatsService />
            </ProtectedRoute>
          }
        />

        <Route
          path="/change-password"
          exact
          element={
            <ProtectedRoute token={token}>
              <ChangePassword />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-listing"
          exact
          element={
            <ProtectedRoute token={token}>

              <AddListItem />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-listing/:id"
          exact
          element={
            <ProtectedRoute token={token}>
              <EditListItem />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
