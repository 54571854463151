import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
// import ChartistGraph from "react-chartist";
import { Nav, Dropdown } from "react-bootstrap";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useGetCountQuery } from "../../RTK/Sevice/ApiStats";

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "#1a4a5b",
      borderColor: "rgba(75,192,192,1)",
    },
  ],
};

export default function Graph() {
  const { data: statsApi } = useGetCountQuery();

  const AfterUsingReviewDatalabels = statsApi?.AfterUsingReviewData?.map(
    (item) => item.AfterUsingReview
  );
  const AfterUsingReviewDatadataPoints = statsApi?.AfterUsingReviewData?.map(
    (item) => item.count
  );

  const AfterUsingReviewData = {
    labels: AfterUsingReviewDatalabels,
    datasets: [
      {
        label: "Feedback Responses",
        data: AfterUsingReviewDatadataPoints,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const AgeRangeDatalabels = statsApi?.AgeRangeData?.map(
    (item) => item.TotalAgeRange
  );
  const AgeRangeDatadataPoints = statsApi?.AgeRangeData?.map(
    (item) => item.count
  );

  const AgeRangeData = {
    labels: AgeRangeDatalabels,
    datasets: [
      {
        label: "Feedback Responses",
        data: AgeRangeDatadataPoints,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const DidTheResourcesHaveThePositiveEffectDatalabels =
    statsApi?.DidTheResourcesHaveThePositiveEffectData?.map(
      (item) => item.DidTheResourcesHaveThePositiveEffect
    );
  const DidTheResourcesHaveThePositiveEffectDatadataPoints =
    statsApi?.DidTheResourcesHaveThePositiveEffectData?.map(
      (item) => item.count
    );

  const DidTheResourcesHaveThePositiveEffectData = {
    labels: DidTheResourcesHaveThePositiveEffectDatalabels,
    datasets: [
      {
        label: "Feedback Responses",
        data: DidTheResourcesHaveThePositiveEffectDatadataPoints,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const LikelyToUseAgainDatalabels = statsApi?.LikelyToUseAgainData?.map(
    (item) => item.LikelyToUseAgain
  );
  const LikelyToUseAgainDatadataPoints = statsApi?.LikelyToUseAgainData?.map(
    (item) => item.count
  );

  const LikelyToUseAgainData = {
    labels: LikelyToUseAgainDatalabels,
    datasets: [
      {
        label: "Feedback Responses",
        data: LikelyToUseAgainDatadataPoints,
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  return (
    <Fragment>
      <>
        <div className="ct-chart" id="chartHours">
          <div className="row new-css">
            <div className="col-sm-3">Yearly Survey Submitted</div>
            <div className="col-sm-3">Weekly Survey Submitted</div>
            <div className="col-sm-3">Monthly Survey Submitted</div>
            <div className="col-sm-3">Today Survey Submitted</div>

            <div className="col-sm-3">{statsApi?.YearlySurveySubmitted}</div>
            <div className="col-sm-3">{statsApi?.WeeklySurveySubmitted}</div>
            <div className="col-sm-3">{statsApi?.MonthlySurveySubmitted}</div>
            <div className="col-sm-3">{statsApi?.TodaySurveySubmitted}</div>

            <div className="col-sm-6">
              <h5 className="graphical-header">After Using Review</h5>
              <Doughnut data={AfterUsingReviewData} />
            </div>
            <div className="col-sm-6">
              <h5 className="graphical-header">Age Range</h5>

              <Doughnut data={AgeRangeData} />
            </div>

            <div className="col-sm-6">
              <h5 className="graphical-header">
                Did The Resources Have The Positive Effect
              </h5>

              <Doughnut data={DidTheResourcesHaveThePositiveEffectData} />
            </div>
            <div className="col-sm-6">
              <h5 className="graphical-header">Like To Use Again</h5>

              <Doughnut data={LikelyToUseAgainData} />
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
}
