import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const statsApi = createApi({
  reducerPath: "statsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["Stats"],

    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
    providesTags: ["Stats"],
  }),
  endpoints: (builder) => ({
    getCount: builder.query({
      query: () => `SurveyResponseRoutes/SeeSurveyStats`,
      providesTags: ["Stats"],
    }),
  }),
});

export const { useGetCountQuery } = statsApi;
