import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import Landing from '../Components/Index/Landing'


function Dashboard() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3'>
                      <Menu/>
                    </div>
                    <div className='col-sm-9'>
<Landing/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard