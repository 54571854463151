import React, { Fragment, useState } from 'react';
import Create from "../../Assets/Create.png";
import Delete from "../../Assets/Delete.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetListQuery, useDeleteJokeMutation } from "../../RTK/Sevice/ApiListing";
import Pagination from "../Pagination";
import toast from "react-hot-toast";
import DeleteModal from "../Modal/DeleteModal";
import { Input } from 'antd';

const { Search } = Input;

function LeadTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState();
  const [deleteJokeMutation] = useDeleteJokeMutation();

  const handlePageChange = (page) => {
    setPage(page);
  };


  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteJokeMutation(id);

      if (response.data) {
        toast.success("Data Deleted Successfully");
        refetch();
      } else if (response.error) {
        toast.error(response.error.message || "An error occurred");
      } else {
        toast.error("An unknown error occurred");
      }
    } catch (error) {
      toast.error("An unexpected error occurred");
    }
  };

  const { data: list, refetch } = useGetListQuery({ page, title });

  const handleSearch = (value) => {
    setTitle(value);
    setPage(1); // Reset page to 1 when performing a new search
    refetch({ page: 1, title: value }); // Fetch data with search query
  };

  return (
    <Fragment>
      <div className="lists leads">
        <div className='search'>
          <Search
            placeholder="Search by title For Jokes and Quotes"
            onSearch={handleSearch}
            onChange={(e) => setTitle(e.target.value)}
          />
         
        </div>
        {/* <select onChange={handleChange}>
            <option value={""}>Type</option>
            <option value={"Quote"}>Quote</option>
            <option value={"Joke"}>Joke</option>
          </select> */}
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Type</th>
              <th>Category</th>
              <th>Title</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {list?.result?.items.map((items, index) => (
              <tr key={items?._id}>
                <td>
                  <p>{index + 1 + (page - 1) * 10}</p>
                </td>
                <td>{items?.JokeTypeData?.name} </td>
                <td>{items?.JokeCategoryData?.name}</td>
                <td>{items?.Title}</td>
                <td>
                  <Link to={`/edit-listing/${items?._id}`}>
                    <img src={Create} alt="view" />{" "}
                  </Link>
                  <img
                    onClick={() => handleModalOpen(items?._id)}
                    src={Delete}
                    alt="view"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          TotalData={list?.result?.totalCount}
          handlePageChange={handlePageChange}
          currentPage={page}
        />
        <DeleteModal
          handleDelete={handleDelete}
          id={id}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
        />
      </div>
    </Fragment>
  );
}

export default LeadTable;
