import React, { Fragment } from 'react'
import { useGetCountQuery } from "../../RTK/Sevice/ApiDashboard";

function Landing() {
  const { data: getCount } = useGetCountQuery();
  console.log(getCount, "<=====count");
  return (
    <Fragment>
      <div className="col-sm-12 dashboard">
        <div className="row">
          <div className="col-sm-6 boxes" data-aos="fade-down">
            <h3>{getCount?.data[0]?.count}</h3>
            <p>No of {getCount?.data[0]?.name}</p>
          </div>

          <div className="col-sm-6 boxes b2" data-aos="fade-down">
            <h3>{getCount?.data[1]?.count}</h3>
            <p>No of {getCount?.data[1]?.name}</p>
          </div>

          <div className="col-sm-6 boxes" data-aos="fade-down">
            <h3>{getCount?.data[2]?.count}</h3>
            <p>No of {getCount?.data[2]?.name}</p>
          </div>

          <div className="col-sm-6 boxes b2" data-aos="fade-down">
            <h3>{getCount?.data[3]?.count}</h3>
            <p>No of {getCount?.data[3]?.name}</p>
          </div>

          
        </div>
      </div>
    </Fragment>
  );
}

export default Landing