import { Navigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  if (!props.token) {
    // If token is not present or empty, navigate to login
    return <Navigate to="/" replace />;
  }
  console.log(props.token, "tokes")

  // If token is present, render the children
  return props.children;
};

export default ProtectedRoute;
