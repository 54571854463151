import React, { Fragment, useState } from "react";
import Logo from "../../Assets/Logo.png";
import LogoIco from "../../Assets/Logoico.png";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Menu() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const menuItems = [
    { label: "Dashboard", to: "/dashboard", index: 0 },
    { label: "Listing", to: "/listing", index: 1 },
    { label: "Add New Item", to: "/add-listing", index: 2 },
    { label: "Service Stats", to: "/service-stats", index: 4 },
    { label: "Change Credentials", to: "/change-password", index: 3 },
  ];

  const destroyToken = () => {
    localStorage.removeItem("token");
  };

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/");
  };

  return (
    <Fragment>
      <div className="col-sm-12 sidebar desktop">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <ul>
          {menuItems.map((item) => (
            <li key={item.index}>
              <Link
                to={item.to}
                className={item.to === location.pathname ? "active" : ""}
                onClick={() => {
                  handleItemClick(item.index);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>

        <button className="logout" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <div className="mobile">
        <img src={Logo} alt="Logo" className="moblogo" />
        <Button variant="primary sidemenumob" onClick={handleShow}>
          <img src={LogoIco} alt="asd" />
        </Button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src={Logo} alt="Logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="sidebar">
              {menuItems.map((item) => (
                <li key={item.index}>
                  <Link
                    to={item.to}
                    className={item.to === location.pathname ? "active" : ""}
                    onClick={() => {
                      handleItemClick(item.index);
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>

            <button className="logout" onClick={handleLogout}>
              Logout
            </button>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Fragment>
  );
}

export default Menu;
