import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// const getTokenFromCookies = () => {
//   return Cookies.get('token');
// };

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["Joke"],
    prepareHeaders: (headers, { getState }) => {
      // ... your authentication logic
      return headers;
    },
    providesTags: ["Joke"],
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (values) => ({
        url: "",
        method: "POST",
        body: values,
      }),
    }),

    getTypes: builder.query({
      query: () => "JokeType/GetJokeTypeListing",
      providesTags: ["Joke"],
    }),

    getCategory: builder.query({
      query: () => "JokeCategory/GetJokeCategoryListing",
      providesTags: ["Joke"],
    }),

    // createJoke: builder.mutation({
    //   query: (values) => ({
    //     url: "Joke/CreateJoke",
    //     method: "POST",
    //     body: values,
    //   }),
    //   invalidatesTags: ["Joke"],
    // }),

  
    // updateJoke: builder.mutation({
    //   query: ({ id, formData }) => ({
    //     url: `Joke/EditJoke/${id}`,
    //     method: "PUT",
    //     body: formData,
    //   }),
    //   invalidatesTags: ["Joke"],
    // }),

    getSingleJoke: builder.query({
      query: (id) => `Joke/GetSingleJoke/${id}`,
      providesTags: ["Joke"],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetTypesQuery,
  useGetCategoryQuery,
  useGetSingleJokeQuery,
  // useCreateJokeMutation,

  // useUpdateJokeMutation,
} = api;

