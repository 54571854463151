import React, { Fragment } from 'react'
import Menu from '../Components/Sidebar/Menu'
import StatsData from '../Components/Stats/StatsData'


function StatsService() {
    return (
        <Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3'>
                        <Menu />
                    </div>
                    <div className='col-sm-9'>
                        <StatsData />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default StatsService