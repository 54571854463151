import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Helper function to get the token from cookies
const getToken = () => Cookies.get("token");

// Helper function to add authorization header
const addAuthorizationHeader = (headers) => {
  const token = getToken();
  if (token) {
    headers.set("Authorization", `${token}`);
  }
  return headers;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["auth"],
    prepareHeaders: (headers, { getState }) => {
      return addAuthorizationHeader(headers);
    },
    providesTags: ["auth"],
  }),
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: (values) => ({
        url: "LoginAdmin",
        method: "POST",
        body: values,
      }),
      onSuccess: (result, variables, api, { dispatch }) => {
        const token = result.data.token;
        console.log(token, "<======r");
        Cookies.set("token", token);
      },
    }),

    changePassword: builder.mutation({
      query: (values) => ({
        url: "ChangePassword",
        method: "PUT",
        body: values,
      }),
      // Include the token in the headers for this specific endpoint
      headers: (headers) => addAuthorizationHeader(headers),
    }),
  }),
});

export const { useAdminLoginMutation, useChangePasswordMutation } = authApi;
