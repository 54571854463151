import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const listing = createApi({
  reducerPath: "listing",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["Listing"],
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
    providesTags: ["Listing"],
  }),

  endpoints: (builder) => ({
    getList: builder.query({
      query: ({ page, title }) => {
        const titleQuery =`&Title=${encodeURIComponent(title)}`;
        console.log(title,"asd")
        return `Joke/GetJokeListing?page=${page}${titleQuery}`;
      },
      providesTags: ["Listing"],
    }),

    deleteJoke: builder.mutation({
      query: (id) => ({
        url: `Joke/DeleteJoke/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Listing"],
    }),
    createJoke: builder.mutation({
      query: (values) => ({
        url: "Joke/CreateJoke",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Listing"],
    }),

    updateJoke: builder.mutation({
      query: ({ id, formData }) => ({
        url: `Joke/EditJoke/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Listing"],
    }),
  }),
});

export const {
  useGetListQuery,
  useDeleteJokeMutation,
  useUpdateJokeMutation,
  useCreateJokeMutation,
} = listing;