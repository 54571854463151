import { configureStore } from '@reduxjs/toolkit';

import { api } from '../RTK/Sevice/ApiService';
import { listing } from './Sevice/ApiListing';
import { authApi } from "./Sevice/ApiAuth";
import { dashboardApi } from "./Sevice/ApiDashboard";
import { statsApi } from "./Sevice/ApiStats";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [listing.reducerPath]: listing.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [statsApi.reducerPath]: statsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(listing.middleware)
      .concat(authApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(statsApi.middleware),
});

export default store;