import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    tagTypes: ["dashboard"],

    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
    providesTags: ["dashboard"],
  }),
  endpoints: (builder) => ({
    getCount: builder.query({
      query: () => `Joke/jokeCount`,
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetCountQuery } = dashboardApi;
