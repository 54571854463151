import React, { Fragment, useEffect } from "react";
import { Button, Form, Input } from "antd";
import Logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { useAdminLoginMutation } from "../../RTK/Sevice/ApiAuth";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Loader from "../Loader";

function Login() {
  const navigate = useNavigate();
  const [adminLoginMutation, { isLoading }] = useAdminLoginMutation();

  const onFinish = async (values) => {
    try {
      const response = await adminLoginMutation(values);

      if (response.data && response.data.token) {
        const token = response.data.token;
        toast.success("Login successful");
        Cookies.set("token", token); // Set token with path option
        navigate("/dashboard"); // Redirect to dashboard after login
      } else if (response.error) {
        toast.error(response.error.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Unexpected Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/dashboard"); // Redirect to dashboard if token exists
    }
  }, [navigate]);

  return (
    <Fragment>
      <div className="col-sm-12 login">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          data-aos="fade-down"
          data-aos-duration="8000"
        >
          <img src={Logo} alt="img" />
          <Form.Item
            name="Email" // Changed from "Email" to "email"
            rules={[{ required: true, message: "Invalid email" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <div className="btnSection">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="save save1">
                {isLoading ? <Loader /> : "Login"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Fragment>
  );
}

export default Login;