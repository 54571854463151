import React, { Fragment } from "react";
import { Button, Form, Input, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useChangePasswordMutation } from "../../RTK/Sevice/ApiAuth";
import toast from "react-hot-toast";

function TextUpload() {
  const [changePasswordMutation, { isLoading, isError }] =
    useChangePasswordMutation();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  console.log(isError, "isError");

  const onFinish = async (values) => {
    try {
      const response = await changePasswordMutation(values);

      if (response.data) {
        toast.success("Password Updated Successfully");
      } else if (response.error) {
        console.error("API Error:", response.error);
        toast.error(response.error.data.message || "An error occurred");
      }
    } catch (error) {
      console.error("Unexpected Error:", error);
      toast.error(error.message || "An unexpected error occurred");
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <Fragment>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="editProfileForm"
      >
        <Form.Item
          label="Current Password"
          name="previouspassword"
          rules={[{ required: true, message: "Current Password is required" }]}
        >
          <Input.Password placeholder="Current Password" />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newpassword"
          rules={[{ required: true, message: "New Password is required" }]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newpassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newpassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="save save2"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}

export default TextUpload;
